import { toast } from '@/app/wrappers/Toaster/Toaster';
import { TransactionDirection, useTransactions } from '@/services/useTransactions';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import DateRangePicker from '@/shared/components/DateRangePicker';
import DollarAmount from '@/shared/components/DollarAmount';
import {
  ControlsRow,
  DEFAULT_RESULTS_PER_TABLE_PAGE,
  TableStatusText,
  WarningText,
} from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import { colors } from '@/shared/styles';
import { TransactionStatus, TransactionType } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Button, Dropdown, Table, TextInput } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

const LedgerCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;

const PayoutIdWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;
`;

const TRANSACTIONS_PER_PAGE = DEFAULT_RESULTS_PER_TABLE_PAGE;

const transactionStatusToDisplay = (status: TransactionStatus | null) => {
  if (!status) {
    return <></>;
  }

  // Branch on the status type
  switch (status) {
    case TransactionStatus.Pending:
      return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
    case TransactionStatus.PendingReview:
      return <TableStatusText color={colors.accentOrange}>Pending Review</TableStatusText>;
    case TransactionStatus.Clearing:
      return <TableStatusText color={colors.accentOrange}>Clearing</TableStatusText>;
    case TransactionStatus.Authorized:
      return <TableStatusText color={colors.accentOrange}>Authorized</TableStatusText>;
    case TransactionStatus.Canceled:
      return <TableStatusText color={colors.accentRed}>Canceled</TableStatusText>;
    case TransactionStatus.Returned:
      return <TableStatusText color={colors.accentRed}>Returned</TableStatusText>;
    case TransactionStatus.Rejected:
      return <TableStatusText color={colors.accentRed}>Rejected</TableStatusText>;
    case TransactionStatus.Sent:
      return <TableStatusText color={colors.accentGreen}>Completed</TableStatusText>;
    default:
      return <p>N/A</p>;
  }
};

function transactionTypeToString(type: TransactionType): string {
  switch (type) {
    case TransactionType.BookTransfer:
      return 'Book Transfer';
    default:
      // Most types are fine to print as-is
      return type;
  }
}

export default function LedgerTable() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week').toDate());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toDate()); // Use the _future_ :O
  const [searchString, setSearchString] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedType, setSelectedType] = useState<TransactionType | ''>('');
  const [selectedDirection, setSelectedDirection] = useState<TransactionDirection>('Both');
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState<
    TransactionStatus | ''
  >('');

  const {
    data: fetchedTransactions,
    isLoading,
    isError,
  } = useTransactions(
    startDate,
    endDate,
    selectedType !== '' ? selectedType : null,
    searchString,
    selectedDirection,
    selectedTransactionStatus !== '' ? selectedTransactionStatus : null
  );

  const startIdx = pageNumber * TRANSACTIONS_PER_PAGE;
  const endIdx = (pageNumber + 1) * TRANSACTIONS_PER_PAGE;
  const transactionsForTable = (fetchedTransactions || []).slice(startIdx, endIdx).map((txn) =>
    // Map fetched transactions to displayable data
    ({
      id: txn.transactionId,
      payoutId: txn?.metadata?.payoutId ? (
        <PayoutIdWrapper
          onClick={(e) => {
            e.stopPropagation();
            if (txn?.metadata?.payoutId) {
              navigator.clipboard.writeText(txn.metadata.payoutId);
              toast({
                type: 'info',
                message: 'Payout ID copied to clipboard',
                duration: 4500,
              });
            }
          }}
        >
          {txn.metadata.payoutId}
        </PayoutIdWrapper>
      ) : (
        '-'
      ),
      date: dayjs(txn.createdAt).format('D MMM YYYY, h:mma'),
      description: txn.description || txn.summary,
      type: transactionTypeToString(txn.transactionType),
      status: transactionStatusToDisplay(txn.transactionStatus),
      amount: (
        <DollarAmount amountCents={txn.amount} showSign positive={txn.direction === 'Credit'} />
      ),
    })
  );

  const lastPage = Math.floor((fetchedTransactions || []).length / TRANSACTIONS_PER_PAGE);

  return (
    <LedgerCard>
      <CardTitle>Ledger</CardTitle>
      <BetweenWrapper>
        <ControlsRow>
          <TextInput
            value={searchString}
            width={400}
            placeholder="Filter by search term"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchString(e.target.value || '');
              setPageNumber(0);
            }}
          />
          <DateRangePicker
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            onChange={(dates) => {
              const [d1, d2] = dates;
              setStartDate(d1);
              setEndDate(d2);
              setPageNumber(0);
            }}
          />
          <Dropdown
            options={[
              { label: 'Any Type', value: '' },
              { label: 'Ach', value: TransactionType.Ach },
              { label: 'Wire', value: TransactionType.Wire },
              { label: 'Book Transfer', value: TransactionType.BookTransfer },
              { label: 'Card', value: TransactionType.Card },
              { label: 'Atm', value: TransactionType.Atm },
              { label: 'Check', value: TransactionType.Check },
              { label: 'Dispute', value: TransactionType.Dispute },
              { label: 'Interest', value: TransactionType.Interest },
              { label: 'Fee', value: TransactionType.Fee },
              { label: 'Other', value: TransactionType.Other },
            ]}
            closeOnOutsideClick
            onSelect={(opt) => {
              setSelectedDirection('Both');
              setSelectedType(opt.value as TransactionType);
              setPageNumber(0);
            }}
          />
          {selectedType === TransactionType.Ach && (
            <Dropdown
              options={[
                { label: 'Credit and Debit', value: 'Both' },
                { label: 'Credit', value: 'Credit' },
                { label: 'Debit', value: 'Debit' },
              ]}
              closeOnOutsideClick
              onSelect={(opt) => {
                setSelectedDirection(opt.value as TransactionDirection);
                setPageNumber(0);
              }}
            />
          )}
          <Dropdown
            options={[
              { label: 'Any Status', value: '' },
              { label: 'Canceled', value: TransactionStatus.Canceled },
              { label: 'Pending', value: TransactionStatus.Pending },
              { label: 'Rejected', value: TransactionStatus.Rejected },
              { label: 'Returned', value: TransactionStatus.Returned },
              { label: 'Completed', value: TransactionStatus.Sent },
            ]}
            closeOnOutsideClick
            onSelect={(opt) => {
              setSelectedTransactionStatus(opt.value as TransactionStatus);
              setPageNumber(0);
            }}
          />
        </ControlsRow>
        <Button
          text="Generate CSV"
          sizeVariant="big"
          colorVariant="brand"
          width="165px"
          icon="file-text"
          onClick={() =>
            generateAndDownloadCsv(fetchedTransactions || [], (txn) => ({
              transactionId: txn.transactionId,
              payoutId: txn?.metadata?.payoutId,
              transactionType: txn.transactionType,
              transactionStatus: txn.transactionStatus,
              amount: formatCentsAsDollars(txn.amount),
              summary: txn.summary,
              description: txn.description,
              direction: txn.direction,
              createdAt: dayjs(txn.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            }))
          }
        />
      </BetweenWrapper>
      {(fetchedTransactions?.length || 0) >= 1000 && (
        <WarningText>
          Warning: This search matched too many results &mdash; only the first 1000 results are
          shown. Please narrow your search to see more complete results.
        </WarningText>
      )}

      <Table
        loading={isLoading}
        error={isError}
        empty={(transactionsForTable || []).length === 0}
        data={transactionsForTable}
        columns={[
          { title: 'ID', field: 'id', flex: 1 },
          { title: 'Payout ID', field: 'payoutId', flex: 1, wrapOverflow: false, isText: false },
          { title: 'Date', field: 'date', flex: 2 },
          { title: 'Transaction Type', field: 'type', flex: 2 },
          {
            title: 'Description',
            field: 'description',
            flex: 6,
            wrapOverflow: false,
          },
          { title: 'Status', field: 'status', flex: 2 },
          { title: 'Amount', field: 'amount', flex: 2, isText: false },
        ]}
        width="100%"
        height="fit-content"
      />
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
    </LedgerCard>
  );
}
