import { ControlsRow } from '@/shared/components/CreationComponents';
import { NewFeatureAlert } from '@/shared/components/NewFeatureAlert';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import {
  CustomerProfile,
  PayoutGroupDefinition,
  PayoutGroupDefinitionFilterSelections,
} from '@/shared/types';
import { NEW_SUPPORT_REQUEST_URL } from '@/shared/utils/external-urls';
import { Dropdown } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';

type Props = {
  customerProfile?: CustomerProfile;
  onPayoutGroupDefinitionSelect: (arg: PayoutGroupDefinitionFilterSelections) => void;
  payoutGroupDefinitions?: Array<PayoutGroupDefinition>;
};

export default function PayoutGroupDefinitionFilters({
  customerProfile,
  onPayoutGroupDefinitionSelect,
  payoutGroupDefinitions,
}: Props) {
  // customer has nothing setup
  if (!payoutGroupDefinitions || payoutGroupDefinitions.length === 0) {
    if (shouldShowFeaturePromo(customerProfile)) {
      return (
        <NewFeatureAlert>
          Use payout groups to filter payouts based on your preferences. For example, use it to
          categorize payouts based on your lines of businesses.{' '}
          <a href={NEW_SUPPORT_REQUEST_URL} target="_blank" rel="noreferrer">
            Contact support
          </a>{' '}
          to use this feature.
        </NewFeatureAlert>
      );
    }

    return null;
  }

  const orderedPayoutGroupDefinitions = payoutGroupDefinitions.sort((a, b) => a.order - b.order);

  return (
    <BetweenWrapper>
      <ControlsRow>
        {orderedPayoutGroupDefinitions.map((definition) => (
          <Dropdown
            key={definition.tagKey}
            options={[
              { label: `Any ${definition.name}`, value: '' },
              ...definition.allowedValues.map((value) => {
                return {
                  label: value.name,
                  value: value.tagValue,
                };
              }),
            ]}
            closeOnOutsideClick
            onSelect={(opt) => {
              onPayoutGroupDefinitionSelect({
                [definition.name]: opt.value.length === 0 ? null : opt.label,
              });
            }}
          />
        ))}
      </ControlsRow>
    </BetweenWrapper>
  );
}

/*
  Show the feature promo for a recent user, or a bit after the feature relase
*/
function shouldShowFeaturePromo(customerProfile: CustomerProfile | undefined) {
  if (customerProfile) {
    const featureLaunchDate = dayjs('2024-03-30');
    const customerProfileCreationDate = dayjs(customerProfile.createdAt);
    const effectiveCutoffDate = featureLaunchDate.isAfter(customerProfileCreationDate)
      ? featureLaunchDate
      : customerProfileCreationDate;

    return effectiveCutoffDate.isAfter(dayjs().subtract(1, 'month'));
  } else {
    return false;
  }
}
