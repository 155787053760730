/**
 * Layout for the Dashboard for Customers to do things.
 */
import { LoadingGuard } from '@/app/wrappers/LoadingGuard';
import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import Sidebar from '@/shared/components/Sidebar/Sidebar';
import { SidebarItemT } from '@/shared/components/Sidebar/SidebarItem';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { LoadingScope, TabPermissions } from '@/shared/types';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomerTopbar from './CustomerTopbar';

const CustomerTabWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow-y: scroll;
`;

const CustomerContentWrapper = styled(CustomerTabWrapper)`
  gap: 30px;
  padding: 30px;
`;

export default function CustomerDashboardLayout() {
  const { data: permissions } = useCustomerProfilePermissions();
  const showNewUi = useGetFeatureToggle('showNewUi');
  const location = useLocation();
  const isPayoutsRoute = location.pathname === '/customer/payouts';
  const customerSidebarItems: SidebarItemT[] = [
    // {
    //   text: 'Overview',
    //   to: '/customer',
    //   icon: 'home',
    //   disabled: true,
    // },
    {
      text: 'Payouts',
      to: '/customer/payouts',
      icon: 'credit-card',
      disabled: permissions && permissions.payoutsTab === TabPermissions.none,
    },
    {
      text: 'Workers',
      to: '/customer/workers',
      icon: 'users',
      disabled: permissions && permissions.workersTab === TabPermissions.none,
    },
    {
      text: 'Finance',
      to: '/customer/finance',
      icon: 'dollar-sign',
      disabled: permissions && permissions.financeTab === TabPermissions.none,
    },
    {
      text: 'Taxes',
      to: '/customer/taxes',
      icon: 'briefcase',
      disabled: permissions && permissions.taxTab === TabPermissions.none,
    },
    {
      text: 'User Management',
      to: '/customer/users',
      icon: 'user',
      disabled: permissions && permissions.usersTab === TabPermissions.none,
    },
    // {
    //   text: 'API Management',
    //   to: '/customer/api',
    //   icon: 'code',
    //   disabled: true,
    // },
    // {
    //   text: 'Settings',
    //   to: '/customer/settings',
    //   icon: 'settings',
    //   disabled: true,
    // },
  ];

  if (showNewUi || isPayoutsRoute)
    return (
      <>
        <Sidebar items={customerSidebarItems} />
        <LoadingGuard loadingScope={LoadingScope.customerDashboard}>
          <CustomerTabWrapper>
            <Outlet />
          </CustomerTabWrapper>
        </LoadingGuard>
      </>
    );
  return (
    <>
      <Sidebar items={customerSidebarItems} />
      <LoadingGuard loadingScope={LoadingScope.customerDashboard}>
        <CustomerTabWrapper>
          <CustomerTopbar />
          <CustomerContentWrapper>
            <Outlet />
          </CustomerContentWrapper>
        </CustomerTabWrapper>
      </LoadingGuard>
    </>
  );
}
