import { trackCoreCustomerEvent } from '@/analytics';
import { useGetInTouch } from '@/services/useGetInTouch';
import { colors } from '@/shared/styles';
import { IconName } from '@checkrx/pay-component-library/dist/Icon/Icon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import checkrPayPhones from './checkr-pay-phones.png';
import checkrPayLogo from './logo.png';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 1000px;
  max-width: 960px;
  overflow-y: auto;
`;

const TitleSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 864px;
  margin-bottom: 64px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 1%;
  color: #1a2026;
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #1a2026c7;
  line-height: 1.5;
  letter-spacing: 0%;
  font-family: 'Public Sans', sans-serif;
`;

const ImageGraphic = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 344px;
  margin-bottom: 64px;
`;

const Logo = styled.img`
  width: auto;
  height: 24px;
  margin-bottom: 48px;
  @media (max-width: 854px) {
    margin-bottom: 32px;
  }
`;

const CardRow = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 48px;

  @media (max-width: 854px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  width: 264px;
  gap: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: left;
  text-align: left;
  flex-direction: column;

  @media (max-width: 854px) {
    width: 100%;
  }
`;

const CardTitle = styled.h3`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  text-align: left;
  color: #1a2026;
  font-family: 'Public Sans', sans-serif;
`;

const CardText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #1a2026c7;
  text-align: left;
  font-weight: 400;
  font-family: 'Public Sans', sans-serif;
`;

const glowingEffect = keyframes`
  0% { border: 2px solid ${colors.accentTeal}; }
  50% { border: 2px solid ${colors.accentGreen}; }
  100% { border: 2px solid ${colors.accentTeal}; }
`;

const DemoCard = styled.div`
  width: 100%;
  max-width: 856px;
  padding: 30px 32px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  animation: ${glowingEffect} 4s infinite;
`;

const CompletedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;
const Button = styled.button`
  background-color: #0a57a4;
  color: ${colors.trueWhite};
  border-radius: 4px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  height: 36px;
  &:hover {
    background-color: #04417f;
  }
`;

type BGCData = {
  accountId: string | null;
  companyName: string | null;
  companyPhone: string | null;
  companyWebsite: string | null;
  email: string | null;
  fullName: string | null;
  userId: string | null;
};

export default function BGCCustomerOnboardingHome() {
  const [completed, setCompleted] = useState(false);
  const { mutateAsync: getInTouch } = useGetInTouch();
  const [searchParams] = useSearchParams();
  const bgcData: BGCData = {
    accountId: searchParams.get('accountId'),
    companyName: searchParams.get('companyName'),
    companyPhone: searchParams.get('companyPhone'),
    companyWebsite: searchParams.get('companyWebsite'),
    email: searchParams.get('email'),
    fullName: searchParams.get('fullName'),
    userId: searchParams.get('userId'),
  };
  const handleRequestDemo = () => {
    getInTouch({
      name: bgcData?.fullName ?? '',
      email: bgcData?.email ?? '',
      phoneNumber: bgcData?.companyPhone ?? '',
      companyName: bgcData?.companyName ?? '',
      accountId: bgcData?.accountId ?? '',
      companyWebsite: bgcData?.companyWebsite ?? '',
    });
    setCompleted(true);
  };

  // track amplitude event
  useEffect(() => {
    if (bgcData.userId && bgcData.email && bgcData.companyName) {
      trackCoreCustomerEvent(
        bgcData.userId,
        bgcData.email,
        bgcData.companyName,
        'Core Customer visited pay CTA'
      );
    }
  }, [bgcData.userId, bgcData.email, bgcData.companyName]);

  const cards: {
    icon: IconName;
    title: string;
    text: string;
  }[] = [
    {
      icon: 'bookmark',
      title: 'Seamless Payment Experience',
      text: `Manage payroll across multiple payout methods - 
      instant payment or direct deposits - all within a single, intuitive platform`,
    },
    {
      icon: 'users',
      title: 'White Glove Support',
      text: `Comprehensive managed earner support, operations & 
      covered loss liability. We handle all payment support related issues`,
    },
    {
      icon: 'bookmark',
      title: 'Unlock New Revenue Stream',
      text: `Earn predictable interchange revenue share 
      through our base product, which is free for earners`,
    },
  ];

  return (
    <HomeWrapper>
      <a href="https://www.checkrpay.com" target="_blank" rel="noopener noreferrer">
        <Logo src={checkrPayLogo} alt="Checkr Pay Logo" />
      </a>
      <TitleSection>
        <Title>Interested in offering instant payments to your workforce?</Title>
        <Subtitle>
          Checkr Pay offers instant, customizable payment solutions for your W2 and 1099 Workforce,
          embedded directly in your application or accessed as a standalone experience.
        </Subtitle>
      </TitleSection>

      <ImageGraphic src={checkrPayPhones} alt="Checkr Pay Phones" />

      <CardRow>
        {cards.map((card, index) => (
          <Card key={index}>
            <CardTitle>{card.title}</CardTitle>
            <CardText>{card.text}</CardText>
          </Card>
        ))}
      </CardRow>

      <DemoCard>
        {completed ? (
          <CompletedMessage>
            <Title>Thank You!</Title>
            <Subtitle>We will be in touch soon.</Subtitle>
          </CompletedMessage>
        ) : (
          <>
            <Subtitle>
              {bgcData.fullName ? `${bgcData.fullName},  interested` : 'Interested'} in learning
              more about how Checkr Pay can help
              {bgcData.companyName ? ` ${bgcData.companyName}` : ' you'} attract and retain top
              talent? Click the button below and someone on our team will get in touch!
            </Subtitle>
            <Button onClick={handleRequestDemo}>Request a demo</Button>
          </>
        )}
      </DemoCard>
    </HomeWrapper>
  );
}
