import { usePayouts } from '@/services/usePayouts';
import { useWorkers } from '@/services/useWorkers';
import { useGetOneTaxes } from '@/services/useWorkerTaxes';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import DollarAmount from '@/shared/components/DollarAmount';
import { ControlsRow, DEFAULT_RESULTS_PER_TABLE_PAGE } from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { useMultiSelect } from '@/shared/hooks/useMultiSelect';
import { TaxesStatus, Worker } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Button, Check, Dropdown, Modal, Table, TextInput } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import ConfirmFileModal from './ConfirmFileModal';
import { MultiFileButton } from './MultiFileButton';
import PreviousIncomeModal from './PreviousIncomeModal';
import { PreviousWorkModal, TaxYears, WorkerWithEarnings } from './taxes.types';
import { taxesStatusRenderMap, TAX_YEARS } from './utils';
import { WorkerTaxAction } from './WorkerTaxAction';

const TaxesTableCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
  user-select: none;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const WORKERS_PER_PAGE = DEFAULT_RESULTS_PER_TABLE_PAGE;

export const TaxesTable = () => {
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<TaxesStatus | ''>('');
  const [taxFilingYear, setTaxFilingYear] = useState<TaxYears>(2023);
  const { data: fetchedWorkers, isError, isLoading } = useWorkers();
  const [selectedWorker, setSelectedWorker] = useState<WorkerWithEarnings>();
  const { allSelected, selectedLength, selectedIds, handleSelectDeselectAll, handleSelect } =
    useMultiSelect(fetchedWorkers ?? []);

  // modals
  const [showPreviousWorkIncomeModal, setShowPreviousWorkIncomeModal] = useState(false);
  const [previousWorkModalType, setPreviousWorkModalType] = useState<PreviousWorkModal>('create');
  const [showConfirmFileModal, setShowConfirmFileModal] = useState(false);
  const {
    data: fetchedPayouts,
    isError: payoutsError,
    isLoading: payoutsLoading,
  } = usePayouts({
    startDate: dayjs(`01-01-${taxFilingYear}`).toDate(),
    endDate: dayjs(`12-31-${taxFilingYear}`).toDate(),
  });

  const { mutateAsync: getOneTaxes } = useGetOneTaxes();

  const filterWorkerByStatus = (w: Worker): boolean => {
    if (selectedStatus === '') return true;
    if (!w?.['1099nec']?.[taxFilingYear]) {
      return selectedStatus === 'NOT_STARTED';
    }
    return w?.['1099nec']?.[taxFilingYear]?.status === selectedStatus;
  };
  const filterWorkerByName = (w: Worker): boolean => {
    if (search === '') return true;
    return (w.profile?.legalName ?? '').toLowerCase().includes(search.toLowerCase());
  };

  const filteredWorkers = fetchedWorkers?.filter((w) => {
    return filterWorkerByStatus(w) && filterWorkerByName(w);
  });

  const workersWithPayoutTotals = filteredWorkers?.reduce<WorkerWithEarnings[]>(
    (acc, curr, idx) => {
      const workerPayouts = fetchedPayouts?.filter(
        (p) => p.workerId === curr.id && p.status === 'completed'
      );
      const earnedTotal = workerPayouts?.reduce((acc, curr) => {
        acc = acc + curr.amountCents;
        return acc;
      }, 0);
      acc[idx] = { ...curr, earnedCentsTotal: earnedTotal ?? 0 };
      return acc;
    },
    [] as WorkerWithEarnings[]
  );

  const startIdx = pageNumber * WORKERS_PER_PAGE;
  const endIdx = (pageNumber + 1) * WORKERS_PER_PAGE;

  const handleOpenConfirmFileModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowConfirmFileModal(true);
  };

  const handleOpenPreviousWorkModal = (type: PreviousWorkModal, worker: WorkerWithEarnings) => {
    setPreviousWorkModalType(type);
    setSelectedWorker(worker);
    setShowPreviousWorkIncomeModal(true);
  };

  const handleGenerateCsv = () => {
    generateAndDownloadCsv(workersWithPayoutTotals ?? [], (worker) => ({
      id: worker.id,
      email: worker?.profile?.email,
      phoneNumber: worker?.profile?.phoneNumber,
      firstName: worker?.profile?.legalName?.split(' ')[0] || '',
      // We want the last name to include the rest of the names
      lastName: worker?.profile?.legalName?.split(' ').slice(1).join(' ') || '',
      taxForm: '1099-NEC',
      taxYear: taxFilingYear,
      onPlatformEarnings: formatCentsAsDollars(worker.earnedCentsTotal),
      offPlatformEarnings: formatCentsAsDollars(
        Number(worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome ?? 0)
      ),
      totalEarnings: formatCentsAsDollars(
        worker.earnedCentsTotal +
          Number(worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome ?? 0)
      ),
      deliveryMethod: 'Paperless',
      workerStatus: worker?.['1099nec']?.[taxFilingYear]?.status ?? 'NOT_STARTED',
    }));
  };

  const handleViewPdf = async (worker: Worker) => {
    const newTaxObj = await getOneTaxes({ workerProfileId: worker.id, year: taxFilingYear });
    const link = document.createElement('a');
    link.href = newTaxObj.payeeUrl ?? worker?.['1099nec']?.[taxFilingYear]?.payeeUrl ?? '';

    window.open(link.href);
  };

  const workersForTable = (workersWithPayoutTotals || []).slice(startIdx, endIdx).map((worker) =>
    // Map fetched workers to displayable data
    ({
      id: worker.id,
      checked: (
        <Check
          checked={selectedIds?.includes(worker.id)}
          onClick={(e) => handleSelect(e, worker.id)}
        />
      ),
      legalName: worker.profile?.legalName || worker.id,
      taxForm: '1099-NEC',
      taxYear: taxFilingYear,
      earnings: <DollarAmount amountCents={worker.earnedCentsTotal} />,
      externalEarnings: worker?.['1099nec']?.[taxFilingYear] ? (
        <DollarAmount
          amountCents={Number(worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome)}
        />
      ) : (
        '----'
      ),
      status: taxesStatusRenderMap[worker?.['1099nec']?.[taxFilingYear]?.status ?? 'NOT_STARTED'],
      actions: (
        <WorkerTaxAction
          worker={worker}
          taxYear={taxFilingYear}
          onViewPdf={handleViewPdf}
          onOpenPreviousWorkModal={handleOpenPreviousWorkModal}
          onOpenConfirmFileModal={handleOpenConfirmFileModal}
        />
      ),
    })
  );

  const lastPage = Math.floor((fetchedWorkers || []).length / WORKERS_PER_PAGE);

  return (
    <TaxesTableCard>
      <CardTitle>Worker 1099s</CardTitle>
      <HeaderContainer>
        <ControlsRow>
          <TextInput
            value={search}
            width={400}
            placeholder="Search by name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
              setPageNumber(0);
            }}
          />
          <Dropdown
            options={[
              { label: 'Any Status', value: '' },
              { label: 'Not started', value: 'NOT_STARTED' },
              { label: 'Created', value: 'CREATED' },
              { label: 'Filed', value: 'FILED' },
              { label: 'Accepted', value: 'ACCEPTED' },
              { label: 'Rejected', value: 'REJECTED' },
            ]}
            closeOnOutsideClick
            onSelect={(opt) => {
              setSelectedStatus(opt.value as TaxesStatus);
              setPageNumber(0);
            }}
          />
          <Dropdown
            options={TAX_YEARS.map((y) => ({ label: y.toString(), value: y.toString() }))}
            closeOnOutsideClick
            onSelect={(opt) => setTaxFilingYear(Number(opt.value) as TaxYears)}
          />
        </ControlsRow>
        <ButtonsContainer>
          <b>{selectedLength} earners selected</b>
          <MultiFileButton
            selectedIds={selectedIds}
            workers={workersWithPayoutTotals ?? []}
            taxYear={taxFilingYear}
          />
          <Button
            text="Generate CSV"
            sizeVariant="big"
            colorVariant="brand"
            width="165px"
            icon="file-text"
            onClick={handleGenerateCsv}
          />
        </ButtonsContainer>
      </HeaderContainer>
      <Table
        data={workersForTable}
        error={isError || payoutsError}
        loading={isLoading || payoutsLoading}
        empty={(workersForTable || []).length === 0}
        columns={[
          {
            title: <Check checked={allSelected} onClick={handleSelectDeselectAll} />,
            field: 'checked',
            flex: 0.5,
            wrapOverflow: false,
            isText: false,
          },
          { title: 'Worker', field: 'legalName', flex: 2, wrapOverflow: false },
          { title: 'Tax form', field: 'taxForm', flex: 1, wrapOverflow: false },
          { title: 'Tax year', field: 'taxYear', flex: 1, wrapOverflow: false },
          { title: 'Earnings', field: 'earnings', flex: 1, wrapOverflow: false },
          {
            title: 'External earnings',
            field: 'externalEarnings',
            flex: 2,
            wrapOverflow: false,
          },
          { title: 'Status', field: 'status', flex: 1, isText: false },
          { title: 'Actions', field: 'actions', flex: 2, isText: false },
        ]}
        width="100%"
        height="fit-content"
      />
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
      <Modal
        isOpen={showPreviousWorkIncomeModal}
        close={() => {
          setShowPreviousWorkIncomeModal(false);
        }}
        headerText={
          previousWorkModalType === 'create'
            ? 'Previous income before using Checkr Pay'
            : 'Request correction for 1099-NEC'
        }
        subHeaderText={
          previousWorkModalType === 'create'
            ? 'Please enter the previous income earned before using Checkr Pay'
            : 'Please enter the previous income earned you would like to update to'
        }
        modalContent={
          <PreviousIncomeModal
            worker={selectedWorker as WorkerWithEarnings}
            taxYear={taxFilingYear}
            type={previousWorkModalType}
            onClose={() => setShowPreviousWorkIncomeModal(false)}
          />
        }
      />
      <Modal
        isOpen={showConfirmFileModal}
        close={() => {
          setShowConfirmFileModal(false);
        }}
        headerText="Confirm your filing"
        subHeaderText={`You are filing for ${selectedWorker?.profile?.legalName}
        for the ${taxFilingYear} tax year`}
        modalContent={
          <ConfirmFileModal
            worker={selectedWorker as WorkerWithEarnings}
            taxYear={taxFilingYear}
            onClose={() => setShowConfirmFileModal(false)}
            onViewPdf={handleViewPdf}
          />
        }
      />
    </TaxesTableCard>
  );
};
