import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useBankingAccountBalance } from '@/services/useBankingAccount';
import { useCreateOnePayout } from '@/services/usePayouts';
import { useWorkers } from '@/services/useWorkers';
import { ValueText } from '@/shared/components/ConfirmationModals.styled';
import { LabelText, SuccessText } from '@/shared/components/CreationComponents';
import ModalContent from '@/shared/components/ModalContent';
import { Worker } from '@/shared/types';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { isAmount, isCsvSafeString, isWorkerId } from '@/shared/validation';
import { DropdownInput, TextArea, TextInput } from '@checkrx/pay-component-library';
import { isAxiosError } from 'axios';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmPayout } from './CreatePayout/ConfirmPayout';

type Props = {
  onClose: () => void;
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 24px;
`;

export const CreatePayoutModal: FC<Props> = ({ onClose }) => {
  const [workerId, setWorkerId] = useState<Worker['id']>('');
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [metadata, setMetadata] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [workerSearch, setWorkerSearch] = useState('');

  const [workerIdErr, setWorkerIdErr] = useState<string | null>(null);
  const [paymentAmountErr, setPaymentAmountErr] = useState<string | null>(null);
  const [metadataErr, setMetadataErr] = useState<string | null>(null);
  const [descriptionErr, setDescriptionErr] = useState<string | null>(null);

  const { data: workers } = useWorkers();
  const { data: balance } = useBankingAccountBalance();
  const searchedWorkers = (workers ?? []).filter((w) => {
    const joinedInfo = `${w.id} ${w.profile?.email} ${w.profile?.legalName}`;
    return joinedInfo.toLowerCase().includes(workerSearch.toLowerCase());
  });

  const generateWorkerDropdownLabel = (worker: Worker) => {
    if (worker.profile?.legalName && worker.profile.email) {
      return `${worker.profile?.legalName} - ${worker.profile?.email}`;
    }
    if (worker.profile?.legalName && !worker.profile.email) {
      return worker.profile.legalName;
    }
    if (!worker.profile?.legalName && worker.profile?.email) {
      return worker.profile.email;
    }
    return worker.id;
  };

  const { isLoading: createPayoutIsLoading, mutateAsync: createSinglePayout } = useCreateOnePayout({
    onSuccess: () => {
      toast({
        type: 'success',
        message: 'Payout created successfully',
        duration: 4500,
      });
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        if (status === 404) {
          toast({
            type: 'error',
            message: `We couldn't find a worker with the specified worker ID. 
              Please try again or contact customer support for assistance.`,
            duration: 4500,
          });
        } else {
          const errorMessage =
            error.response?.data?.error_message ||
            'Error creating payout - Please try again or contact customer support for assistance.';
          toast({
            type: 'error',
            message: errorMessage,
            duration: 4500,
          });
        }
      } else {
        toast({
          type: 'error',
          message:
            'Error creating payout - Please try again or contact customer support for assistance.',
          duration: 4500,
        });
      }
    },
  });

  const workerDropdownOptions =
    searchedWorkers?.map((w) => ({
      label: generateWorkerDropdownLabel(w),
      value: w.id,
    })) ?? [];

  const validateInputs = () => {
    let canSubmit = true;

    // Check Amount
    if (!paymentAmount) {
      canSubmit = false;
      setPaymentAmountErr('Payment amount is blank. Please enter valid payment amount');
    } else if (!isAmount(paymentAmount)) {
      canSubmit = false;
      setPaymentAmountErr('Payment amount is invalid. Please enter a valid payment amount');
    } else {
      setPaymentAmountErr(null);
    }

    // Check WorkerId
    if ((workerId || '').length < 1) {
      canSubmit = false;
      setWorkerIdErr('Worker Id is blank. Please enter valid Worker Id.');
    } else if (!isWorkerId(workerId)) {
      canSubmit = false;
      setWorkerIdErr('Worker Id is invalid. Please enter a valid workerId');
    } else {
      setWorkerIdErr(null);
    }

    // Optional Description
    if (description && description.length > 0) {
      if (description.length > 1000) {
        canSubmit = false;
        setDescriptionErr('Description cannot be more than 1000 characters');
      } else if (!isCsvSafeString(description)) {
        canSubmit = false;
        setDescriptionErr('Description cannot begin with special characters +, -, =, or @');
      } else {
        setDescriptionErr(null);
      }
    } else {
      setDescriptionErr(null);
    }
    // Optional Metadata
    if (metadata && metadata.length > 0) {
      if (metadata.length > 256) {
        canSubmit = false;
        setMetadataErr('Metadata cannot be more than 256 characters');
      } else if (!isCsvSafeString(metadata)) {
        canSubmit = false;
        setMetadataErr('Metadata cannot begin with special characters +, -, =, or @');
      } else {
        setMetadataErr(null);
      }
    } else {
      setMetadataErr(null);
    }
    return canSubmit;
  };

  const handleClear = () => {
    setWorkerId('');
    setPaymentAmount(0);
    setMetadata('');
    setDescription('');
  };

  const handleCancel = () => {
    onClose();
    handleClear();
  };

  const handleSubmit = async () => {
    const canProceed = validateInputs();
    if (canProceed) {
      if (workerId && paymentAmount) {
        await createSinglePayout({
          workerId,
          amount: paymentAmount,
          metadata: metadata !== '' ? metadata : undefined,
          description: description !== '' ? description : undefined,
        });
      }
    }

    handleClear();
    onClose();
  };

  const selectedWorker = workers?.find((w) => w.id === workerId) ?? ({} as Worker);

  return (
    <ModalContent
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      confirmSubmit
      disableSubmit={!workerId || (paymentAmount ?? 0) < 1}
      confirmContent={
        <ConfirmPayout
          worker={selectedWorker}
          balance={balance ?? 0}
          paymentAmount={paymentAmount}
          metadata={metadata}
          description={description}
        />
      }
      isSubmitLoading={createPayoutIsLoading}
    >
      <BalanceWrapper>
        <LabelText>Current Account Balance: </LabelText>
        <ValueText>
          <SuccessText>{formatCentsAsDollars(balance ?? 0)}</SuccessText>
        </ValueText>
      </BalanceWrapper>
      <FormGrid>
        <InputWrapper>
          <LabelText>Worker</LabelText>
          <DropdownInput
            options={workerDropdownOptions}
            placeholder="Search by worker name, email, or id"
            onSelect={(v: string) => setWorkerId(v)}
            onChange={(v: string) => setWorkerSearch(v)}
            value={workerId}
            invalid={!!workerIdErr}
            errorText={workerIdErr ?? undefined}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelText>Payment Amount</LabelText>
          <TextInput
            placeholder="Payment Amount"
            value={
              paymentAmount
                ? Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format((paymentAmount || 0) / 100)
                : undefined
            }
            onChange={(e) => {
              // Parse from Intl number format to integer value
              if (e.target.value.includes('$')) {
                setPaymentAmount(
                  parseInt(e.target.value.slice(1).replaceAll(',', '').split('.').join(''))
                );
              } else {
                setPaymentAmount(parseInt(e.target.value.replaceAll(',', '')));
              }
            }}
            invalid={!!paymentAmountErr}
            errorText={paymentAmountErr ?? undefined}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelText>Metadata</LabelText>
          <TextArea
            width="100%"
            height="120px"
            placeholder="Enter metadata (optional)"
            onChange={(e) => setMetadata(e.target.value)}
            value={metadata}
            invalid={!!metadataErr}
            errorText={metadataErr ?? undefined}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelText>Description</LabelText>
          <TextArea
            width="100%"
            height="120px"
            placeholder="Enter description (optional)"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            invalid={!!descriptionErr}
            errorText={descriptionErr ?? undefined}
          />
        </InputWrapper>
      </FormGrid>
    </ModalContent>
  );
};
