import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { CardTitle } from '@/shared/components/Card';
import { Button, Dropdown, Modal, Table, TextInput } from '@checkrx/pay-component-library';

import {
  useCustomer,
  useCustomerPayoutGroupDefinitions,
  useCustomerProfilePermissions,
} from '@/services/useCustomerProfile';
import { usePayouts } from '@/services/usePayouts';
import { DateRangeSelector } from '@/shared/components/DateRangeSelector';
import DollarAmount from '@/shared/components/DollarAmount';
import MenuButton from '@/shared/components/MenuButton/MenuButton';
import {
  ControlsRow,
  DEFAULT_RESULTS_PER_TABLE_PAGE,
  TableStatusText,
} from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import { colors } from '@/shared/styles';
import {
  CustomerProfile,
  Payout,
  PayoutGroupDefinitionFilterSelections,
  PayoutStatus,
  TabPermissions,
} from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import ConfirmPayoutCancellationModal from './ConfirmPayoutCancellationModal';
import ConfirmRepayPayoutModal from './ConfirmRepayPayoutModal';
import PayoutGroupDefinitionFilters from './PayoutGroupDefinitionFilters';

const PayoutsTableCard = styled.div`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
  display: flex;
  flex-direction: column;
  background: ${colors.primaryWhite};
  padding: 24;
  align-items: start;
  justify-content: start;
  padding: 20px;
`;

const TableWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const PAYOUTS_PER_PAGE = DEFAULT_RESULTS_PER_TABLE_PAGE;

const payoutStatusToDisplay = (payout: Payout) => {
  const status = payout?.status;
  if (!status) {
    return <></>;
  }

  // Branch on the status type
  switch (status) {
    case PayoutStatus.Pending:
      return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
    case PayoutStatus.Canceled:
      if ((payout?.repayments?.length ?? 0) > 0) {
        return <TableStatusText color={colors.accentRed}>Repaid</TableStatusText>;
      }
      return <TableStatusText color={colors.accentRed}>Canceled</TableStatusText>;
    case PayoutStatus.Failed:
      return <TableStatusText color={colors.accentRed}>Failed</TableStatusText>;
    case PayoutStatus.Completed:
      return <TableStatusText color={colors.accentGreen}>Completed</TableStatusText>;
    case PayoutStatus.Queued:
      return <TableStatusText color={colors.accentOrange}>Queued</TableStatusText>;
    default:
      return <p>N/A</p>;
  }
};

const payoutCompletionDisplayDate = (payout: Payout) => {
  return payout.completedAt
    ? dayjs(payout.completedAt).format('D MMM YYYY, h:mma')
    : payout.expectedCompletionDate
      ? dayjs(payout.expectedCompletionDate).format('D MMM YYYY')
      : null;
};

const payoutCompletionDisplayCsv = (payout: Payout) => {
  return payout.completedAt
    ? dayjs(payout.completedAt).format('YYYY-MM-DD HH:mm:ss')
    : payout.expectedCompletionDate
      ? dayjs(payout.expectedCompletionDate).format('YYYY-MM-DD')
      : null;
};

type Props = {
  payWorkerId?: string;
  customerProfile: CustomerProfile;
};

export default function PayoutsTable({ payWorkerId, customerProfile }: Props) {
  const allTimeStartDate = dayjs(customerProfile.createdAt).toDate();
  const defaultStartDate = dayjs().subtract(1, 'week').startOf('day').toDate();
  const defaultEndDate = dayjs().add(1, 'day').toDate();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [searchString, setSearchString] = useState('');
  const [pageNumber, setPageNumber] = useState(0);

  // Worker filtering state
  const [selectedStatus, setSelectedStatus] = useState<PayoutStatus | ''>('');
  const [workerId] = useState(payWorkerId || '');
  const [workerEmail] = useState('');
  const [workerLegalName] = useState('');

  const [showConfirmRepayPayoutModal, setShowConfirmRepayPayoutModal] = useState(false);
  const [showConfirmPayoutCancellationModal, setShowConfirmPayoutCancellationModal] =
    useState(false);
  const [selectedPayout, setSelectedPayout] = useState<Payout | undefined>();
  const [selectedPayoutGroupDefinitions, setSelectedPayoutGroupDefinitions] = useState<
    PayoutGroupDefinitionFilterSelections | undefined
  >();

  const {
    data: fetchedPayouts,
    isLoading: isPayoutsLoading,
    isError,
  } = usePayouts({
    startDate,
    endDate,
    workerId,
    email: workerEmail,
    legalName: workerLegalName,
    searchString,
    selectedStatus: selectedStatus !== '' ? selectedStatus : null,
    payoutGroupDefinitionFilterSelections: selectedPayoutGroupDefinitions,
  });

  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customer, isLoading: isCustomerLoading } = useCustomer();
  const { data: payoutGroupDefinitions, isLoading: isPayoutGroupDefinitionsLoading } =
    useCustomerPayoutGroupDefinitions();

  const isLoading = isPayoutsLoading || isCustomerLoading || isPayoutGroupDefinitionsLoading;

  const reversiblePayoutStatuses = [PayoutStatus.Completed, PayoutStatus.Queued];
  const canRepayPayout = (payout: Payout) => {
    if (permissions?.executeRepayments !== TabPermissions.write) return false;
    if (!reversiblePayoutStatuses.includes(payout.status)) return false;
    if (!customer?.canUseRepayments) return false;
    return true;
  };

  const cancelablePayoutStatuses = [PayoutStatus.Pending, PayoutStatus.Queued, PayoutStatus.Failed];
  const canCancelPayout = (payout: Payout) => {
    return (
      permissions?.payoutsTab === TabPermissions.write &&
      cancelablePayoutStatuses.includes(payout.status)
    );
  };

  const showPayoutActions = (payout: Payout) => {
    return canCancelPayout(payout) || canRepayPayout(payout);
  };

  const startIdx = pageNumber * PAYOUTS_PER_PAGE;
  const endIdx = (pageNumber + 1) * PAYOUTS_PER_PAGE;
  const payoutsForTable = (fetchedPayouts || []).slice(startIdx, endIdx).map((payout) =>
    // Map fetched payouts to displayable data
    ({
      id: payout.id,
      createdDate: dayjs(payout.createdAt).format('D MMM YYYY, h:mma'),
      completionDate: payoutCompletionDisplayDate(payout),
      workerId: payout.workerId,
      email: payout?.workerResource?.profile?.email || '',
      name: payout?.workerResource?.profile?.legalName || '',
      description: payout?.description || '',
      metadata: payout?.metadata || '',
      amount: <DollarAmount amountCents={payout.amountCents} />,
      status: payoutStatusToDisplay(payout),
      menu: showPayoutActions(payout) ? (
        <MenuButton
          options={[
            {
              label: 'Cancel payout',
              state: canCancelPayout(payout) ? 'enabled' : 'hidden',
              action: () => {
                setSelectedPayout(payout);
                setShowConfirmPayoutCancellationModal(true);
              },
            },
            {
              label: 'Repay payout',
              state: canRepayPayout(payout) ? 'enabled' : 'hidden',
              action: () => {
                setSelectedPayout(payout);
                setShowConfirmRepayPayoutModal(true);
              },
            },
          ]}
          icon="more-vertical"
          width="35px"
          colorVariant="white"
        />
      ) : (
        <div />
      ),
    })
  );

  const lastPage = Math.floor((fetchedPayouts || []).length / PAYOUTS_PER_PAGE);

  return (
    <>
      <PayoutsTableCard>
        <CardTitle>Payouts</CardTitle>
        <BetweenWrapper>
          <ControlsRow>
            <TextInput
              value={searchString}
              width={400}
              placeholder="Filter by search term"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(e.target.value || '');
                setPageNumber(0);
              }}
            />
            <DateRangeSelector
              allTimeStartDate={allTimeStartDate}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              onChange={(_, start, end) => {
                setStartDate(start);
                setEndDate(end);
                setPageNumber(0);
              }}
            />
            <Dropdown
              options={[
                { label: 'Any Status', value: '' },
                { label: 'Queued', value: PayoutStatus.Queued },
                { label: 'Pending', value: PayoutStatus.Pending },
                { label: 'Completed', value: PayoutStatus.Completed },
                { label: 'Canceled', value: PayoutStatus.Canceled },
                { label: 'Failed', value: PayoutStatus.Failed },
              ]}
              closeOnOutsideClick
              onSelect={(opt) => {
                setSelectedStatus(opt.value as PayoutStatus);
                setPageNumber(0);
              }}
            />
          </ControlsRow>
          <Button
            text="Generate CSV"
            sizeVariant="big"
            colorVariant="brand"
            width="165px"
            icon="file-text"
            onClick={() =>
              generateAndDownloadCsv(fetchedPayouts || [], (payout) => ({
                payoutAmount: formatCentsAsDollars(payout.amountCents),
                payoutId: payout.id,
                workerId: payout.workerId,
                workerEmail: payout.workerResource?.profile?.email,
                completionDate: payoutCompletionDisplayCsv(payout),
                payoutStatus: payout.status,
                payoutSummary: payout.description,
                metadata: payout?.metadata || '',
                ...(payoutGroupDefinitions || []).reduce(
                  (all, definition) => {
                    all[definition.name] = payout.groups?.[definition.name] || '';
                    return all;
                  },
                  {} as Record<string, string>
                ),
              }))
            }
          />
        </BetweenWrapper>

        <PayoutGroupDefinitionFilters
          customerProfile={customerProfile}
          payoutGroupDefinitions={payoutGroupDefinitions}
          onPayoutGroupDefinitionSelect={(
            selectedDefinitions: PayoutGroupDefinitionFilterSelections
          ) => {
            setSelectedPayoutGroupDefinitions((prev) => ({ ...prev, ...selectedDefinitions }));
            setPageNumber(0);
          }}
        />

        <TableWrapper>
          <Table
            data={payoutsForTable}
            error={isError}
            loading={isLoading}
            empty={(payoutsForTable || []).length === 0}
            columns={[
              { title: 'Payout ID', field: 'id', flex: 2, wrapOverflow: true },
              {
                title: 'Worker ID',
                field: 'workerId',
                flex: 2,
                wrapOverflow: false,
              },
              { title: 'Email', field: 'email', flex: 2, wrapOverflow: false },
              { title: 'Name', field: 'name', flex: 1, wrapOverflow: false },
              {
                title: 'Description',
                field: 'description',
                flex: 2,
                wrapOverflow: false,
              },
              { title: 'Date Created', field: 'createdDate', flex: 1 },
              { title: 'Completion Date', field: 'completionDate', flex: 1 },
              { title: 'Status', field: 'status', flex: 1, isText: false },
              { title: 'Amount', field: 'amount', flex: 1, wrapOverflow: false },
              {
                title: 'Metadata',
                field: 'metadata',
                flex: 2,
                wrapOverflow: false,
              },
              {
                title: '',
                field: 'menu',
                flex: 0.5,
              },
            ]}
            width="100%"
            height="fit-content"
          />
        </TableWrapper>
        {lastPage > 0 && (
          <TablePagination
            curPageNumber={pageNumber}
            lastPageNumber={lastPage}
            setCurPageNumber={setPageNumber}
          />
        )}
      </PayoutsTableCard>
      <Modal
        isOpen={showConfirmRepayPayoutModal}
        close={() => {
          setShowConfirmRepayPayoutModal(false);
        }}
        headerText="Repay a payout"
        subHeaderText={`You are repaying a payout for
        ${selectedPayout?.workerResource?.profile?.legalName}`}
        modalContent={
          <ConfirmRepayPayoutModal
            payout={selectedPayout as Payout}
            onClose={() => {
              setShowConfirmRepayPayoutModal(false);
              setSelectedPayout(undefined);
            }}
          />
        }
      />
      <Modal
        isOpen={showConfirmPayoutCancellationModal}
        close={() => {
          setShowConfirmPayoutCancellationModal(false);
        }}
        headerText="Cancel payout"
        subHeaderText={`You are canceling a payout for
        ${selectedPayout?.workerResource?.profile?.legalName}`}
        modalContent={
          <ConfirmPayoutCancellationModal
            payout={selectedPayout as Payout}
            onClose={() => {
              setShowConfirmPayoutCancellationModal(false);
              setSelectedPayout(undefined);
            }}
          />
        }
      />
    </>
  );
}
