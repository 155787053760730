/**
 * The Customer Payout tab for sending a querying payouts
 */

import { useCustomerProfile, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import BatchFileUpload from '@/shared/components/BatchFileUpload';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { Tabs } from '@/shared/components/Tabs/Tabs';
import { colors } from '@/shared/styles';
import { BatchFileType, TabPermissions } from '@/shared/types';
import { Button, Modal, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CreatePayoutModal } from './CreatePayoutModal';
import PayoutsBatchFileTable from './PayoutsBatchFileTable';
import PayoutsTable from './PayoutsTable';

const LoadingPayouts = styled.div`
  width: 368px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${colors.dividerGrey};
  border-radius: 10px;
  padding-left: 40px;
  cursor: not-allowed;
  font-size: 14px;
  color: ${colors.textGrey};
`;

const HeaderRightContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type PayoutTabT = 'payouts' | 'batch';

export default function CustomerPayoutsTab() {
  const [selectedTab, setSelectedTab] = useState<PayoutTabT>('payouts');
  const [showCreatePayout, setShowCreatePayout] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customerProfile, isLoading } = useCustomerProfile();
  const hasWritePermissions = permissions?.payoutsTab === TabPermissions.write;

  const [searchParams] = useSearchParams();
  const payWorkerId = searchParams.get('payWorkerId');

  const tabs = [
    {
      label: 'Payouts',
      value: 'payouts' as PayoutTabT,
    },
    {
      label: 'Batch uploads',
      value: 'batch' as PayoutTabT,
    },
  ];

  if (isLoading || !customerProfile)
    return (
      <LoadingPayouts>
        <div>Loading payouts</div> <Spinner size="24px" />
      </LoadingPayouts>
    );

  return (
    <PageLayout
      title="Payouts"
      headerLeft={
        hasWritePermissions && (
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            onChange={(t) => setSelectedTab(t.value as PayoutTabT)}
          />
        )
      }
      headerRight={
        hasWritePermissions && (
          <HeaderRightContainer>
            <Button
              text="Create Payout"
              onClick={() => setShowCreatePayout(true)}
              colorVariant="brand"
            />
            <Button
              text="Upload Payout Batch File"
              onClick={() => setShowBatchModal(true)}
              colorVariant="accent"
            />
          </HeaderRightContainer>
        )
      }
    >
      {hasWritePermissions ? (
        selectedTab === 'payouts' ? (
          <PayoutsTable payWorkerId={payWorkerId || undefined} customerProfile={customerProfile} />
        ) : (
          <PayoutsBatchFileTable />
        )
      ) : (
        <PayoutsTable payWorkerId={payWorkerId || undefined} customerProfile={customerProfile} />
      )}
      {/* Extra div at the end for bottom spacing */}
      <div />
      <Modal
        isOpen={showCreatePayout}
        close={() => {
          setShowCreatePayout(false);
        }}
        headerText="Create Payout"
        modalContent={<CreatePayoutModal onClose={() => setShowCreatePayout(false)} />}
      />
      <Modal
        isOpen={showBatchModal}
        close={() => {
          setShowBatchModal(false);
        }}
        headerText="Upload Payout Batch File"
        subHeaderText="Pay multiple workers with a single click"
        modalContent={<BatchFileUpload type={BatchFileType.Payout} />}
      />
    </PageLayout>
  );
}
