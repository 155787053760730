import { colors } from '@/shared/styles';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  background-color: ${colors.primaryWhite};
  border-bottom: 1px solid ${colors.dividerGrey};
  padding-left: 12px;
  padding-right: 12px;
  color: ${colors.primaryBlue};
  font-size: 32px;
  font-weight: 600;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

type Props = {
  title: string;
  headerRight?: ReactNode;
  headerLeft?: ReactNode;
  children: ReactNode;
};

export const PageLayout: FC<Props> = ({ title, headerRight, headerLeft, children }) => {
  return (
    <Container>
      <Header>
        <HeaderLeftContainer>
          <div data-testid="page-title">{title}</div>
          {headerLeft}
        </HeaderLeftContainer>
        {headerRight}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};
