import { toast } from '@/app/wrappers/Toaster/Toaster';
import { parseApiErrorDetails } from '@/services/serverApi';
import { useBatchFileCancel, useBatchFiles } from '@/services/useBatchFiles';
import { ReactComponent as DownloadIcon } from '@/shared/assets/download-icon.svg';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import DateRangePicker from '@/shared/components/DateRangePicker';
import { DownloadButton } from '@/shared/components/DownloadButton.styled';
import {
  ActionButtonsGrid,
  batchFileStatusToDisplay,
  ControlsRow,
  TableStatusText,
} from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { colors } from '@/shared/styles';
import { BatchFile, BatchFileStatus, BatchFileType } from '@/shared/types';
import { Button, Dropdown, Table } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import styled from 'styled-components';

const PayoutsBatchFileTableCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;

const TableWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

// Lower this at customer request
const BATCH_FILES_PER_PAGE = 10;

export default function PayoutsBatchFileTable() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'weeks').toDate());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toDate());
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState<BatchFileStatus | ''>('');

  const {
    data: batchFiles,
    isLoading: batchFilesIsLoading,
    isError: batchFilesIsError,
  } = useBatchFiles(startDate, endDate, BatchFileType.Payout, status !== '' ? status : undefined);

  const startIdx = pageNumber * BATCH_FILES_PER_PAGE;
  const endIdx = (pageNumber + 1) * BATCH_FILES_PER_PAGE;
  const batchFilesForTable = batchFiles?.slice(startIdx, endIdx).map((batchFile) => ({
    id: batchFile.inputFileName,
    date: dayjs(batchFile.createdAt).format('D MMM YYYY, h:mma'),
    runAt: dayjs(batchFile.runAt || batchFile.createdAt).format('D MMM YYYY, h:mma'),
    status: batchFileStatusToDisplay(batchFile.status),
    uploader: batchFile.customerProfile?.email || 'Admin',
    successCount: batchFile.successCount,
    failureCount: batchFile.failureCount,
    action: <PayoutsBatchFileActions batchFile={batchFile} />,
  }));
  const lastPage = Math.floor((batchFiles || []).length / BATCH_FILES_PER_PAGE);

  return (
    <PayoutsBatchFileTableCard>
      <CardTitle>Batch Payout Uploads</CardTitle>
      <ControlsRow>
        <DateRangePicker
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          onChange={(dates) => {
            const [d1, d2] = dates;
            setStartDate(d1);
            setEndDate(d2);
          }}
        />
        <Dropdown
          options={[
            { label: 'Any Status', value: '' },
            { label: 'Pending', value: BatchFileStatus.Pending },
            { label: 'Started', value: BatchFileStatus.Started },
            { label: 'Completed', value: BatchFileStatus.Success },
            { label: 'Manual Review', value: BatchFileStatus.ManualReview },
            { label: 'Error', value: BatchFileStatus.Error },
            { label: 'Cancelled', value: BatchFileStatus.Cancelled },
          ]}
          closeOnOutsideClick
          onSelect={(opt) => {
            setStatus(opt.value as BatchFileStatus);
          }}
        />
      </ControlsRow>
      <TableWrapper>
        <Table
          data={batchFilesForTable}
          error={batchFilesIsError}
          loading={batchFilesIsLoading}
          empty={(batchFilesForTable || []).length === 0}
          columns={[
            { title: 'File Name', field: 'id', flex: 2, wrapOverflow: false },
            { title: 'Date Uploaded', field: 'date', flex: 2 },
            { title: 'Scheduled For', field: 'runAt', flex: 2 },
            { title: 'Status', field: 'status', flex: 1, isText: false },
            { title: 'Uploader', field: 'uploader', flex: 2, isText: true },
            {
              title: '# Success',
              field: 'successCount',
              flex: 1,
              isText: true,
            },
            {
              title: '# Error',
              field: 'failureCount',
              flex: 1,
              isText: true,
            },
            { title: 'Actions', field: 'action', flex: 2, isText: false },
          ]}
          height="fit-content"
        />
      </TableWrapper>
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
    </PayoutsBatchFileTableCard>
  );
}

function PayoutsBatchFileActions({ batchFile }: { batchFile: BatchFile }) {
  const { mutateAsync: cancelBatchFile, isLoading: isCancellingBatchFile } = useBatchFileCancel();

  const handleCancelBatchFile = async (batchFileId: string) => {
    try {
      await cancelBatchFile({ batchFileId });
      toast({
        message: 'Batch file cancelled',
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      let message = 'Failed to cancel batch file';
      if (parseApiErrorDetails(err) === 'invalid_status') {
        message = 'This batch file has already been processed and cannot be cancelled';
      }
      toast({
        message,
        type: 'error',
        duration: 5000,
      });
    }
  };

  switch (batchFile.status) {
    case BatchFileStatus.Pending:
      return (
        <ActionButtonsGrid>
          <Button
            icon="download"
            colorVariant="light"
            onClick={() => {
              if (batchFile.inputFileData) {
                fileDownload(batchFile.inputFileData, batchFile.inputFileName);
              }
            }}
          />
          <Button
            icon="x-circle"
            colorVariant="light"
            onClick={() => handleCancelBatchFile(batchFile._id)}
            disabled={isCancellingBatchFile}
          />
        </ActionButtonsGrid>
      );
    case BatchFileStatus.ManualReview:
    case BatchFileStatus.Error:
    case BatchFileStatus.Success:
      return (
        <DownloadButton
          type="button"
          onClick={() => {
            if (batchFile.returnFileData) {
              fileDownload(batchFile.returnFileData, `return-${batchFile.inputFileName}`);
            }
          }}
        >
          <DownloadIcon />
          Download CSV
        </DownloadButton>
      );
    case BatchFileStatus.SystemError:
      return (
        <TableStatusText color={colors.accentRed}>
          Contact support. Do not re-issue these payouts.
        </TableStatusText>
      );
    case BatchFileStatus.Cancelled:
    case BatchFileStatus.Started:
    default:
      return null;
  }
}
